<template>
  <teleport v-if="isMounted" to="#tools">
    <div class="flex items-center gap-5 px-4 border-b border-gray-200 h-11">
      <button type="button" class="inline-flex items-center p-1 text-gray-700 border border-transparent rounded-full hover:bg-gray-50 focus:outline-none" @click="refreshDevice">
        <RefreshIcon :class="[deviceLoading ? 'animate-reverse-spin' : '', 'h-5 w-5']" aria-hidden="true" />
      </button>
      <button v-if="isSuperAdmin" type="button" class="hidden inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="showCopyConfig = true">
        {{ $t("device.action.copyConfig") }}
      </button>
      <button v-if="isSuperAdmin" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="startAdvancedNetworkScan">
        {{ $t("device.action.advancedNetworkScan") }}
      </button>
      <button v-if="isSuperAdmin" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="startBasicNetworkScan">
        {{ $t("device.action.basicNetworkScan") }}
      </button>
      <button v-if="isSuperAdmin" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="showEditOwner = true">
        {{ $t("device.action.changeOwner") }}
      </button>
      <button v-if="isSuperAdmin" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="showApplyConfigTemplate = true">
        {{ $t("device.action.applyConfigTemplate") }}
      </button>
    </div>
  </teleport>
  <DeviceSettingTabs :device-id="deviceId" v-if="isSuperAdmin" />
  <router-view></router-view>
  <DeviceCopyConfigSlide :open="showCopyConfig" @cancel="showCopyConfig = false" />
  <DeviceEditOwnerSlide :open="showEditOwner" :deviceId="device.id" @cancel="showEditOwner = false" />
  <ApplyConfigTemplate :open="showApplyConfigTemplate" @cancel="showApplyConfigTemplate = false"></ApplyConfigTemplate>
</template>

<script>
import { RefreshIcon } from "@heroicons/vue/outline";
import DeviceSettingTabs from "@/components/DeviceSettingTabs.vue";
import { computed, onMounted, ref } from "vue";
import DeviceCopyConfigSlide from "@/views/DeviceCopyConfigSlide";
import DeviceEditOwnerSlide from "@/components/DeviceEditOwnerSlide";
import ApplyConfigTemplate from "@/components/modals/ApplyConfigTemplate";
import { useStore } from "vuex";
import { startNetworkScan } from "@/api/device";
import { useRouter } from "vue-router";
import i18n from "@/i18n";

export default {
  components: {
    DeviceCopyConfigSlide,
    DeviceEditOwnerSlide,
    DeviceSettingTabs,
    RefreshIcon,
    ApplyConfigTemplate
  },
  props: ["deviceId"],
  setup() {
    const alertOpen = ref(false);
    const showCopyConfig = ref(false);
    const showEditOwner = ref(false);
    const showApplyConfigTemplate = ref(false);
    const isMounted = ref(false);
    const store = useStore();
    const router = useRouter();

    const isSuperAdmin = computed(() => {
      return store.getters["auth/isSuperAdmin"];
    });
    const device = computed(() => store.state.device.current);
    const deviceLoading = computed(() => store.state.device.loading);

    onMounted(() => {
      isMounted.value = true;
    })


    const refreshDevice = async () => {
      const serialNumber = store.state.device.current.serialNumber;
      store.dispatch("device/getAggregations");
      await store.dispatch("device/reloadDevice", serialNumber);
      store.commit("showNotification", {
        title: i18n.global.t('notification.deviceRefresh', { serialNumber: serialNumber }),
        type: "info",
      });
    };

    const startAdvancedNetworkScan = async () => {
      const deviceId = store.state.device.current.id;
      const response = await startNetworkScan(deviceId, true);
      const scanId = response.id.split(":")[5].substring(response.id.split(":")[5].indexOf('/') + 1)
      const formattedDeviceId = deviceId.split(":")[5]
      store.commit("showNotification", {
        title: i18n.global.t('notification.deviceAdvancedScan', { deviceId: formattedDeviceId, scanId: scanId }),
        type: "info",
      });
      await router.push({ name: 'deviceTabNetwork' });
    };

    const startBasicNetworkScan = async () => {
      const deviceId = store.state.device.current.id;
      const response = await startNetworkScan(deviceId, false);
      const scanId = response.id.split(":")[5].substring(response.id.split(":")[5].indexOf('/') + 1)
      const formattedDeviceId = deviceId.split(":")[5]
      store.commit("showNotification", {
        title: i18n.global.t('notification.deviceBasicScan', { deviceId: formattedDeviceId, scanId: scanId }),
        type: "info",
      });
    };

    return {
      isMounted,
      alertOpen,
      isSuperAdmin,
      showCopyConfig,
      showEditOwner,
      showApplyConfigTemplate,
      deviceLoading,
      device,
      refreshDevice,
      startAdvancedNetworkScan,
      startBasicNetworkScan,
    };
  },
};
</script>
