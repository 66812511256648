<template>
  <dl class="grid grid-cols-1 gap-3 md:grid-cols-6">
    <!-- SerialNumber -->
    <div class="px-4 py-2 overflow-hidden text-gray-800 bg-gray-100 rounded-lg shadow-md overflow h-18">
      <div class="flex justify-between">
        <div class="justify-between block w-full sm:flex md:block">
          <dt class="text-sm truncate ">{{ $t("device.header.serialNumber") }} <span class="ml-1 text-sm" v-if="device.firmware && device.firmware.version">({{ $t("device.header.firmware") }})</span></dt>
          <dd class="font-semibold text-md" v-if="device">{{ device.serialNumber }} <span class="text-sm" v-if="device.firmware && device.firmware.version">({{ device.firmware.version }})</span> </dd>
        </div>
        <div class="items-start flex-shrink-0 hidden h-14 md:flex">
          <img class="w-auto mx-auto h-14" src="../assets/device.png" alt="CARU" />
        </div>
      </div>
    </div>

    <!-- DeviceID -->
    <div class="px-4 py-2 overflow-hidden text-gray-800 bg-gray-100 rounded-lg shadow-md overflow h-18 ">
      <div class="justify-between block w-full sm:flex md:block">
        <dt class="flex text-sm font-medium truncate">{{ $t("device.header.id") }}</dt>
        <dd class="font-semibold text-md">{{ getDeviceId(device) ? getDeviceId(device) : "-" }}</dd>
      </div>
    </div>

    <!-- Online Status -->
    <div :class="[
            ...onlineStatusColor(device),
            'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 h-18'
          ]">
      <div class="justify-between block w-full sm:flex md:block">
        <dt class="text-sm truncate">{{ $t("device.header.connectivityStatus") }}</dt>
        <dd class="text-sm font-bold" v-if="device.isOnline">
          <p><span class="md:block">Online via 4G/LTE </span>
            <span>{{ getConnectionStatus(device) }}</span>
          </p>
        </dd>
        <dd class="text-sm font-bold text-md" v-else>{{ presenceStatusLabel(device) }}</dd>
      </div>
    </div>

    <div :class="[
            ...powerSupplyStatusColor(device),
            'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 h-18'
          ]">
      <div class="flex justify-between">
        <div class="justify-between block w-full sm:flex md:block">
          <dt class="flex text-sm truncate">{{ $t("stateSummary.label.powerSupplyState") }}</dt>
          <dd class="font-semibold text-md">
            {{ powerSupplyStatusText(device, $t) }}
          </dd>
        </div>
        <div class="items-center flex-shrink-0 hidden h-14 md:flex">
          <BatteryIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
        </div>
      </div>
    </div>

    <div :class="[
            ...braceletPresenceStatusColor(device),
            'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 h-18'
          ]">
      <div class="flex justify-between">
        <div class="justify-between block w-full sm:flex md:block">
          <dt class="flex text-sm truncate">
            {{ $t("stateSummary.label.braceletPresenceState") }}
          </dt>
          <dd class="font-semibold text-md">
            {{ braceletPresenceStatusText(device, $t) }}
          </dd>
        </div>
        <div class="items-center flex-shrink-0 hidden h-14 md:flex">
          <BraceletIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
        </div>
      </div>
    </div>

    <div :class="[
            ...dailyCheckInStatusColor(device),
            'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 h-18'
          ]">
      <div class="flex justify-between">
        <div class="justify-between block w-full sm:flex md:block">
          <dt class="text-sm truncate">{{ $t("device.general.dailyCheckIn") }}</dt>
          <dd class="font-semibold text-md">
            {{ dailyCheckInStatusText(device, $t) }}
          </dd>
        </div>
        <div class="items-center flex-shrink-0 hidden h-14 md:flex">
          <CalendarIcon :class="['h-8', 'w-8', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
        </div>
      </div>
    </div>
  </dl>
</template>

<script>
import {
  powerSupplyStatusColor,
  braceletBatteryStatusColor,
  braceletPresenceStatusColor,
  onlineStatusColor,
  modemStatusColor,
  cloudPresenceStatusColor,
  devicePresenceStatusColor,
  dailyCheckInStatusColor,
  overallBraceletStatusColor,
  wifiStatusColor,
  batteryStatusColor,
  deviceIdentityStatusColor,

} from "@/lib/statusColors";
import { CalendarIcon } from "@heroicons/vue/outline";
import { BraceletIcon } from "@/components/icons";
import { computed } from "vue";
import { useStore } from "vuex";
import { BatteryIcon } from "./icons";
import { useI18n } from "vue-i18n";
import { braceletPresenceStatusText, dailyCheckInStatusText, powerSupplyStatusText } from "@/lib/statusTexts";

export default {
  name: "DeviceStateSummary^",
  methods: {
    braceletPresenceStatusText,
    powerSupplyStatusText,
    onlineStatusColor,
    dailyCheckInStatusColor,
    modemStatusColor,
    overallBraceletStatusColor,
    wifiStatusColor,
    batteryStatusColor,
    deviceIdentityStatusColor,
    dailyCheckInStatusText,
    powerSupplyStatusColor,
    braceletBatteryStatusColor,
    braceletPresenceStatusColor,
    cloudPresenceStatusColor,
    devicePresenceStatusColor,
  },
  components: {
    CalendarIcon,
    BraceletIcon,
    BatteryIcon,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const device = computed(() => store.state.device.current);

    const presenceStatusLabel = (device) => {
      if (device.isOnline) {
        return t("device.errorStatus.ok");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_battery_empty") {
        return t("device.errorStatus.emptyBattery");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_low_power_mode") {
        return t("device.errorStatus.lowPowerMode");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_turned_off") {
        return t("device.errorStatus.off");
      }
      return t("device.errorStatus.missingDevice");
    };

    const getDeviceId = (device) => {
      return device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id).join(", ")
    };

    const getConnectionStatus = (device) => {
      let ret;
      if (device.telemetry && device.telemetry.modem) {
        ret = device.telemetry.modem.operator + "(" + device.telemetry.modem.technology + ")";
      }
      if (ret.length > 25) {
        ret = ret.substring(0, 25) + "...";
      }

      return ret;
    };

    const snakeToCamel = (str) =>
      str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

    return {
      device,
      presenceStatusLabel,
      snakeToCamel,
      getDeviceId,
      getConnectionStatus,
    };
  },
};
</script>
