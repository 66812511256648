<template>

  <div v-show="loading" class="flex justify-center w-full h-full mt-48">
    <LoadingSpinner></LoadingSpinner>
  </div>

  <div v-if="!showTenant" class="h-full m-5 mr-10 overflow-visible overflow-y-scroll pb-36">
    <div>
      <table lass="min-w-full w-dull divide-y divide-gray-200">
        <thead class="bg-gray-100">
          <tr>
            <th v-if="isSuperAdmin" scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.tenantTable.slug") }}
            </th>

            <th scope="col" class="flex-1 w-full px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.tenantTable.name") }}
            </th>

          </tr>
        </thead>
        <tbody v-if="renderComponent" class="bg-white divide-y divide-gray-200">
          <tr v-for="tenant in tenants" :key="tenant.id" class="cursor-pointer hover:bg-slate-100" @click="selectTenant(tenant)">
            <th v-if="isSuperAdmin" scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ tenant.slug }}
            </th>

            <td class="px-6 py-3 whitespace-nowrap">
              {{ tenant.name }}
            </td>
          </tr>

        </tbody>
      </table>

    </div>

    <div class="absolute m-auto bottom-4 right-4">
      <div class="w-10 h-10 m-auto text-white bg-blue-500 rounded-full shadow-lg cursor-pointer hover:bg-blue-700 active:scale-105 active:shadow-lg" @click="showTenant = true; isCreate = true;">
        <div class="flex justify-center h-full m-auto ">
          <PlusIcon class="relative h-5 m-auto"></PlusIcon>
        </div>
      </div>
    </div>
  </div>


  <CreateUpdateTenant v-if="showTenant" :isCreate="isCreate" :tenant="selectedTenant" @cancel="showTenant = false, selectedTenant = {}" @refreshData="refreshData" />

</template>
<script>
import { computed, ref, nextTick } from 'vue';
import { useStore } from 'vuex';

import { PlusIcon } from "@heroicons/vue/outline";
import CreateUpdateTenant from './CreateUpdateTenant.vue';
import LoadingSpinner from "@/components/LoadingSpinner";


export default {
  name: "TenantManagement",
  components: {
    PlusIcon,
    CreateUpdateTenant,
    LoadingSpinner
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch("loadTenants"),
      this.$store.dispatch("users/getUsers"),
      this.$store.dispatch("device/getDevices", true)
    ]);
  },

  setup() {
    const store = useStore();
    const tenants = computed(() => store.state.tenants);

    const renderComponent = ref(true);

    const loading = computed(() => store.state.loading || !renderComponent.value)

    const showTenant = ref(false);
    const isCreate = ref(false);
    const selectedTenant = ref({})


    const isSuperAdmin = computed(() => {
      return store.getters["auth/isSuperAdmin"];
    });

    const selectTenant = (tenant) => {
      selectedTenant.value = tenant;
      showTenant.value = true;
      isCreate.value = false;
    };

    const refreshData = async () => {
      renderComponent.value = false;
      await nextTick();
      Promise.all([
        store.dispatch("loadTenants", true),
        store.dispatch("device/getDevices", true),
        store.dispatch("users/getUsers", true)
      ]);
      selectedTenant.value = {};
      renderComponent.value = true;
    }

    return {
      tenants,
      isSuperAdmin,
      showTenant,
      isCreate,
      selectedTenant,
      selectTenant,
      refreshData,
      renderComponent,
      loading,

    }
  },

};
</script>
