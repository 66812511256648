<template>
    <div class="h-full px-4 pt-2 mx-auto">
        <h1 class="flex-1 px-2 py-2 text-xl font-bold text-gray-900">{{ $t("configTemplates.listPageTitle", 1) }}</h1>
        <div class="flex flex-col h-full pb-10 overflow-y-scroll">
            <div class="inline-block min-w-full py-2 align-middle">
                <div v-if="!showEditTemplate" class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                            <tr>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.name") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.placeholder") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.lastUpdate") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.lastUpdateBy") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.owner") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                    {{ $t("configTemplates.header.applier") }}
                                </th>
                                <th scope="col" class="px-6 py-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">

                                </th>
                            </tr>
                        </thead>


                        <tbody class="bg-white divide-y divide-gray-200">

                            <tr v-for="template in templates" :key="template.id" class="cursor-pointer hover:bg-slate-100" @click="editTemplate(template)">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{ template.name }}
                                </td>
                                <td v-if="getPlaceholders(template.template).length > 3" class="px-6 py-4 whitespace-nowrap ">
                                    <div @mouseover="showTooltip(`tooltip-${template.id}`, 'hover', $event)">
                                        {{ getPlaceholders(template.template).slice(0, 3).join(", ") }},...
                                    </div>
                                    <div :id="`tooltip-${template.id}`" class="inline-block absolute invisible w-max-sm break-words z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
                                        <div v-for="placeholder in getPlaceholders(template.template)" :key="placeholder">
                                            {{ placeholder }}
                                        </div>
                                        <div class="tooltip-arrow" data-popper-arrow></div>
                                    </div>
                                </td>
                                <td v-else class="px-6 py-4 whitespace-nowrap">
                                    {{ getPlaceholders(template.template).join(", ") }}
                                </td>

                                <td class="px-6 py-4 text-gray-600 whitespace-nowrap">
                                    {{ moment(template.updatedAt).format('L') }}
                                </td>
                                <td class="px-6 py-4 text-gray-600 whitespace-nowrap">
                                    {{ template.updater.name }}
                                </td>
                                <td class="px-6 py-4 text-gray-600 whitespace-nowrap">
                                    {{ template.owner.name }}
                                </td>
                                <td class="px-6 py-4 text-gray-600 whitespace-nowrap">
                                    {{ template.appliers.map((applier) => applier.name).join(", ") }}
                                </td>
                                <td class="px-6 py-4 text-gray-600 whitespace-nowrap">
                                    <PencilIcon class="w-5 text-gray-500 cursor-pointer group hover:text-black" aria-hidden="true" @click="editTemplate(template)">
                                    </PencilIcon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <EditConfigTemplate v-else :template="editTemplateItem" :isCreate="isCreate" @cancel="showEditTemplate = false; editTemplateItem = {}"></EditConfigTemplate>
            </div>
        </div>

        <div v-show="!showEditTemplate" class="absolute m-auto bottom-4 right-4">
            <div class="w-10 h-10 m-auto text-white bg-blue-500 rounded-full shadow-lg cursor-pointer hover:bg-blue-700 active:scale-105 active:shadow-lg" @click="showEditTemplate = true; isCreate = true">
                <div class="flex justify-center h-full m-auto ">
                    <PlusIcon class="relative h-5 m-auto"></PlusIcon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditConfigTemplate from "@/components/EditConfigTemplate.vue";
import { PencilIcon, PlusIcon } from "@heroicons/vue/outline";
import { computed } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { Tooltip } from "flowbite";
import moment from "moment";


export default {
    components: {
        PencilIcon,
        EditConfigTemplate,
        PlusIcon
    },
    async mounted() {
        await this.$store.dispatch("device/queryConfigTemplates");
    },
    setup() {

        moment.locale('de-ch');


        const store = useStore();
        const templates = computed(() => store.state.device.current.configTemplates);

        const showEditTemplate = ref(false);
        const isCreate = ref(false);
        const editTemplateItem = ref({})

        const editTemplate = (template) => {
            showEditTemplate.value = true;
            editTemplateItem.value = template;
            isCreate.value = false;
        }

        const getPlaceholders = (template) => {
            const regex = "\\$([^$]+)\\$";
            return [...new Set([...JSON.stringify(template).matchAll(regex)].map((placeholder) => placeholder[0]))];
        }

        const showTooltip = (targetElId, triggerType, event) => {
            const targetEl = document.getElementById(targetElId)
            new Tooltip(targetEl, event.target, { triggerType })
        }

        return {
            moment,
            templates,
            showEditTemplate,
            isCreate,
            editTemplate,
            editTemplateItem,
            getPlaceholders,
            showTooltip

        };
    },
};

</script>
