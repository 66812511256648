<template>

  <div v-show="loading" class="flex justify-center w-full h-full mt-48">
    <LoadingSpinner></LoadingSpinner>
  </div>

  <div v-if="!loading && !showEditUser && !showInviteUser" class="h-full m-5 mr-10 overflow-visible overflow-x-scroll overflow-y-scroll pb-36">
    <div>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-100">
          <tr>
            <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.userTable.name") }}
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.userTable.email") }}
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.userTable.phone") }}
            </th>
            <th scope="col" class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
              {{ $t("userManagement.userTable.tenants") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="renderComponent && localUsers.size > 0" class="h-full bg-white divide-y divide-gray-200">
          <tr v-for=" [id, user]  in  localUsers" :key="id" class="cursor-pointer hover:bg-slate-100" @click="selectUser(user)">
            <td class="px-6 py-3 whitespace-nowrap">
              {{ user.name }}
            </td>
            <td class="px-6 py-3 whitespace-nowrap">
              {{ user.email }}
            </td>
            <td class="px-6 py-3 whitespace-nowrap">
              {{ user.phone }}
            </td>
            <td class="px-6 py-3 whitespace-nowrap" v-html="getTenants(user.tenants)">
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="absolute m-auto bottom-4 right-4">
      <div class="w-10 h-10 m-auto text-white bg-blue-500 rounded-full shadow-lg cursor-pointer hover:bg-blue-700 active:scale-105 active:shadow-lg" @click="showInviteUser = true">
        <div class="flex justify-center h-full m-auto ">
          <PlusIcon class="relative h-5 m-auto"></PlusIcon>
        </div>
      </div>
    </div>
  </div>

  <InviteUser v-if="showInviteUser" @cancel="showInviteUser = false" @refreshData="refreshData"></InviteUser>
  <EditUser v-if="showEditUser" :user="editUser" @cancel="showEditUser = false" @refreshData="refreshData"> </EditUser>
</template>
<script>
import { useStore } from 'vuex';
import { ref, nextTick, computed } from 'vue';
import { PlusIcon } from "@heroicons/vue/outline";
import { hasRights, USER_PERMISSIONS } from "@/lib/userHasPermission";

import InviteUser from "@/components/InviteUser";
import EditUser from "@/components/EditUser";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "UserManagement",
  components: {
    PlusIcon,
    InviteUser,
    EditUser,
    LoadingSpinner
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch("loadTenants"),
      this.$store.dispatch("users/getUsers")
    ]);
  },

  setup() {

    const isAdmin = computed(() => hasRights(USER_PERMISSIONS.ADMIN));
    const isViewer = computed(() => hasRights(USER_PERMISSIONS.VIEWER));
    const isSuperAdmin = computed(() => { return store.getters["auth/isSuperAdmin"]; });

    const store = useStore();
    const users = computed(() => store.state.users.all);
    const localUsers = computed(() => filterUsers());

    const loading = computed(() => store.state.loading || store.state.users.loading)

    const currentTenant = ref([])

    const showInviteUser = ref(false)
    const showEditUser = ref(false)

    const renderComponent = ref(true);
    const editUser = ref({})

    const filterUsers = () => {

      if (!isSuperAdmin.value && !isAdmin.value && !isViewer.value) {
        window.location.reload();
      }

      if (users.value.size > 0) {

        if (isSuperAdmin.value) {
          return users.value;


        } else if (isAdmin.value) {

          const currentUser = getUserFromMap(store.state.auth.user.username)
          if (currentUser && currentUser.tenants) {
            currentUser.tenants.map((t) => t.tenant.id)
            currentTenant.value = currentUser.tenants.map((t) => t.tenant.id)

            let tmpUsers = new Map()
            users.value.forEach((user) => {
              if (user.tenants.filter((tenant) => currentTenant.value.some((cT) => cT == tenant.tenant.id)).length > 0)
                tmpUsers.set(user.id, { ...user })
            })
            return tmpUsers;
          }

        } else if (isViewer.value) {
          editUser.value = getUserFromMap(store.state.auth.user.username)
          if (editUser.value) {
            showEditUser.value = true;
          }
        }
      }
      return new Set()
    }

    const getUserFromMap = (id) => {
      let key = [...users.value.keys()].find(k => k.includes(id))
      return users.value.get(key);
    }

    const getTenants = (data) => {
      let tenants = "";

      for (let tenant of data.slice(0, 2)) {
        tenants += "<span class='text-sm font-light'>" + tenant.role + "</span>: <span class='font-medium'>" + tenant.tenant.name + "</span> "
      }

      return data.length > 2 ? tenants + "..." : tenants;
    }

    const selectUser = (user) => {
      editUser.value = {};
      showEditUser.value = true;
      editUser.value = user;
    }

    const refreshData = async () => {
      renderComponent.value = false;
      await nextTick();
      await Promise.all([
        store.dispatch("loadTenants"),
        store.dispatch("users/getUsers", true)
      ]);

      editUser.value = {};
      renderComponent.value = true;
    }

    return {
      users,
      showInviteUser,
      showEditUser,
      editUser,
      getTenants,
      selectUser,
      refreshData,
      renderComponent,
      isViewer,
      isAdmin,
      currentTenant,
      loading,
      localUsers,
      filterUsers
    }
  },

};
</script>
