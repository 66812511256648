<template>
    <div class="flex h-screen overflow-hidden bg-white">
        <TransitionRoot v-if="user" as="template" :show="sidebarOpen">
            <Dialog as="div" static class="fixed inset-0 z-40 flex lg:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </TransitionChild>
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                    <div class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 right-0 pt-2 -mr-12">
                                <button type="button" class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                                    <span class="sr-only">Close sidebar</span>
                                    <XIcon class="w-6 h-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>
                        <Navigation />
                    </div>
                </TransitionChild>
                <div class="flex-shrink-0 w-14" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
        </TransitionRoot>
        <div v-if="user" class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col w-64">
                <div class="flex flex-col flex-1 h-0 bg-gray-800">
                    <Navigation />
                </div>
            </div>
        </div>
        <div class="flex flex-col flex-1 w-0 overflow-hidden">
            <div v-if="user" class="relative z-10 flex-shrink-0 bg-white">
                <div class="flex h-16 border-b border-gray-200">
                    <button type="button" class="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900 lg:hidden" @click="sidebarOpen = true">
                        <span class="sr-only">Open sidebar</span>
                        <MenuAlt2Icon class="w-6 h-6" aria-hidden="true" />
                    </button>
                    <div class="flex items-center justify-between flex-1 px-4">
                        <div class="flex flex-1">
                            <form class="flex items-center w-full lg:ml-0" v-on:submit.prevent="search">
                                <label for="search_field" class="sr-only">{{ $t("search.placeholder") }}</label>
                                <div class="relative flex w-full text-gray-400 focus-within:text-gray-600 ">
                                    <div class="inset-y-0 left-0 flex items-center pointer-events-none ">
                                        <SearchIcon class="w-5 h-5" aria-hidden="true" />
                                    </div>

                                    <div v-for="( filter, index ) in  chips " :key="index" class="inline-flex items-center px-2 mx-1 text-xs font-normal text-gray-800 bg-gray-200 rounded-full ring-1 ring-inset ring-gray-500/10">
                                        {{ getChipText(filter).length > 20 ? getChipText(filter).substring(0, 20) + '...' : getChipText(filter) }}
                                        <button type="button" @click="chipRemove(filter)" class="group relative -mr-1 h-3.5 w-3.5 rounded-md hover:bg-gray-500/20">
                                            <span class="sr-only">Remove</span>
                                            <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                                                <path d="M4 4l6 6m0-6l-6 6" />
                                            </svg>
                                            <span class="absolute -inset-1" />
                                        </button>
                                    </div>
                                    <input id="search_field" v-model="inputValue" @keydown.enter="updateSearch" @keydown.space="updateSearch" @keydown.delete="backspaceDelete" type="text" :placeholder="$t('search.placeholder')" class="w-full border-0 border-transparent outline-0 focus:border-transparent focus:ring-0" />
                                </div>


                                <button type="button" @click="clearSearch" class="relative inline-flex items-center w-5 h-5 -ml-px text-gray-700 bg-transparent rounded-r-md hover:bg-gray-50 focus:outline-none">
                                    <XIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                </button>
                            </form>
                        </div>
                        <Menu as="div" class="relative flex-shrink-0 ml-12">
                            <div>
                                <MenuButton class="inline-flex items-center justify-center w-8 h-8 bg-gray-500 rounded-full">
                                    <span class="text-sm font-medium leading-none text-white">{{ userInitials() }}</span>
                                </MenuButton>
                            </div>
                            <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
                                <MenuItems class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-slot="{ active }">
                                    <button :class="[
            active ? 'bg-gray-100' : '',
            'w-full text-left block px-4 py-2 text-sm text-gray-700',
        ]
            " @click="signOut">
                                        {{ $t("profileMenu.signOut") }}
                                    </button>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <div id="tools"></div>
            </div>
            <main class="relative flex-1 h-full overflow-y-scroll focus:outline-none lg:overflow-hidden">
                <router-view></router-view>
            </main>
        </div>
    </div>
    <Notification />
</template>

<script>
import { computed, ref, watch } from "vue";
import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { MenuAlt2Icon, SearchIcon, XIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import Navigation from "@/components/NavigationBar.vue";
import Notification from "@/NotificationOverlay";
import { useRouter } from "vue-router";
import "@aws-amplify/ui-vue/styles.css";
import { Auth, I18n } from "aws-amplify";

import { translations } from '@aws-amplify/ui-vue';
I18n.putVocabularies(translations);
I18n.setLanguage('de');

export default {
    components: {
        Notification,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Navigation,
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        SearchIcon,
        MenuAlt2Icon,
        XIcon,
    },
    setup() {

        I18n.putVocabularies({
            de: {
                'Reset your password': 'Passwort zurücksetzen',
                'Reset your Password': 'Zurücksetzen des Passworts',
                'Your passwords must match': 'Passwörter müssen übereinstimmen',
                'Incorrect username or password.': 'Benutzername oder Passwort ist falsch.',
                'Invalid session for the user, session is expired.': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später nochmals.'
            },
        });

        const formFields = {
            forceNewPassword: {
                phone_number: {
                    dialCodeList: ['+49', '+41', '+43']
                },
            },
        }

        const sidebarOpen = ref(false);
        const store = useStore();
        const router = useRouter();
        const searchQuery = computed(() => store.state.searchQuery);
        const searchQueryData = computed(() => store.state.searchQueryData);

        const user = computed(() => store.state.auth.user)

        const inputValue = ref("");
        const chips = ref([]);

        const signOut = async () => {
            await Auth.signOut({ global: true });
            window.location.reload();
        };

        const forgotPassword = async (email) => {
            try {
                const data = await Auth.forgotPassword(email);
                console.log(data);
            } catch (err) {
                console.log(err);
            }
        }

        const userInitials = () => {
            if (user.value && user.value.attributes && user.value.attributes.name) {
                return user.value.attributes.name
                    .split(" ")
                    .slice(0, 2)
                    .reduce((a, v) => a + (v.length ? v[0] : ""), "");
            }

            return "?";
        };

        const clearSearch = async () => {
            await store.dispatch("search", "");
        };


        const updateSearch = async () => {
            let query = [chips.value.join(" "), inputValue.value].join(" ").trim();
            await store.dispatch("search", query);
        };

        const backspaceDelete = ({ which }) => {
            which === 8 && inputValue.value === "" && chips.value.splice(chips.value.length - 1);
        };

        const chipRemove = async (filter) => {
            chips.value.splice(chips.value.indexOf(filter), 1);
            await updateSearch()
        };

        const getChipText = (filter) => {
            if (filter.startsWith("owner:")) {
                const owner = store.state.ownerFilters.find(owner => owner.query === filter);
                if (owner) {
                    return owner.name
                }
            }
            if (filter.startsWith("stateFilter:")) {
                const flattenStateFilters = stateFilter => "subItems" in stateFilter ? [stateFilter].concat(stateFilter.subItems.flatMap(flattenStateFilters)) : [stateFilter];
                const stateFilters = store.state.quickFilters.flatMap(flattenStateFilters);
                const stateFilter = stateFilters.find(stateFilter => stateFilter.query === filter);
                if (stateFilter) {
                    return stateFilter.name
                }
            }
            return filter
        }

        watch(searchQuery, () => {
            if (searchQuery.value) {
                const searchTerms = searchQuery.value.split(" ");
                const filterTerms = searchTerms.filter(term => term.includes(":"));
                const otherTerms = searchTerms.filter(term => !term.includes(":"));
                chips.value = filterTerms
                inputValue.value = otherTerms.join(" ");
            } else {
                chips.value = []
                inputValue.value = ""
            }
        });

        watch(searchQueryData, () => {
            console.log("searchQueryData updated", searchQueryData);
            store.dispatch("device/getDevices", true);
            store.dispatch("device/getAggregations");
            router.push({ name: "devices" });
        });

        return {
            sidebarOpen,
            inputValue,
            chips,
            getChipText,
            clearSearch,
            userInitials,
            signOut,
            updateSearch,
            backspaceDelete,
            chipRemove,
            forgotPassword,
            formFields,
            user
        };
    },
};
</script>
<style>
button.amplify-button.amplify-button--primary {
    background-color: rgb(4, 125, 149) !important;
}

.amplify-phonenumberfield svg {
    display: none;
}
</style>
